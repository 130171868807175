const config = {
  rootProjectId: "root",
  uiBucketName: "unifreescs.c.retter.io",
  appUrl: "https://api.unifreescs.retter.io/",
  cosUrl: "api.unifreescs.retter.io",
  version: "2.1.5",
  captchaKey: "6LeYWt4pAAAAADIyoHXgy3lyD7LugOMI1yVQnzAl",
  stage: "PROD"
}
export default config

